import React from 'react'
import Icon  from "@mui/material/Icon";
import StackOverflow from './assets/stackoverflow.svg'

const StackOverflowIcon = () => (
    <Icon>
        <img src={StackOverflow} alt='StackOverflow Icon' height="100%" width="100%"/>
    </Icon>
)

export default StackOverflowIcon;