import fetch from 'cross-fetch';
import { PullRequest } from './PullRequest';

export class BitbucketApi {
  
  async fetchPullRequestList(
    baseUrl: string,
    repoName: string,
    state?: string,
  ): Promise<PullRequest[]> {
    const response = await fetch(
      `${baseUrl}/api/bitbucketPlugin/repositories/${repoName}/pullrequests/${state}`,
      {
        headers: {
          'Content-Type': 'application/json',
        },
      },
    );
    if (!response.ok) {
      return response.json().then(err => {
        throw new Error(err.error.message || "Unexpected error");
      })
    }
    const data = await response.json();
    return data.values.map((pr: any) => ({
      id: pr.id,
      title: pr.title,
      author: pr.author.display_name,
      created_on: pr.created_on,
      updated_on: pr.updated_on,
      state: pr.state,
      url: pr.links.self.href,
      description: pr.description,
    }));
  }
}