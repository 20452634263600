import React from 'react';
import { Navigate, Route } from 'react-router-dom';
import { apiDocsPlugin, ApiExplorerPage } from '@backstage/plugin-api-docs';
import {
  CatalogEntityPage,
  CatalogIndexPage,
  catalogPlugin,
} from '@backstage/plugin-catalog';
import { CatalogGraphPage } from '@backstage/plugin-catalog-graph';
import {
  CatalogImportPage,
  catalogImportPlugin,
} from '@backstage/plugin-catalog-import';
import { ScaffolderPage, scaffolderPlugin } from '@backstage/plugin-scaffolder';
import { ScaffolderFieldExtensions } from '@backstage/plugin-scaffolder-react';
import { orgPlugin } from '@backstage/plugin-org';
import { SearchPage } from '@backstage/plugin-search';
import {
  techdocsPlugin,
  TechDocsReaderPage,
  TechDocsCustomHome,
  TabsConfig
} from '@backstage/plugin-techdocs';
import { TechDocsAddons } from '@backstage/plugin-techdocs-react';
import { ReportIssue } from '@backstage/plugin-techdocs-module-addons-contrib';
import { UserSettingsPage } from '@backstage/plugin-user-settings';
import { apis } from './apis';
import { entityPage } from './components/catalog/EntityPage';
import { searchPage } from './components/search/SearchPage';
import { Root } from './components/Root';

import { AlertDisplay, OAuthRequestDialog, SignInPage } from '@backstage/core-components';
import { createApp } from '@backstage/app-defaults';
import { AppRouter, FlatRoutes } from '@backstage/core-app-api';

import { RequirePermission } from '@backstage/plugin-permission-react';
import { catalogEntityCreatePermission } from '@backstage/plugin-catalog-common/alpha';

import { microsoftAuthApiRef } from '@backstage/core-plugin-api';

import { BitbucketSecretsExtension } from './scaffolder/BitbucketSecrets';
import { XkcdPage } from 'backstage-plugin-xkcd';
import { HomepageCompositionRoot, VisitListener } from '@backstage/plugin-home';
import { HomePage } from './components/home/HomePage';
import { ExplorePage } from '@backstage-community/plugin-explore';
import { AnnouncementsPage } from '@procore-oss/backstage-plugin-announcements';
import { CostInsightsPage } from '@backstage-community/plugin-cost-insights';
import { Entity } from '@backstage/catalog-model';
import { ToolboxPage } from '@drodil/backstage-plugin-toolbox';
import { shortcutsPlugin } from '@backstage-community/plugin-shortcuts';
import { ChatGPTFrontendPage } from '@enfuse/chatgpt-plugin-frontend';
import { PlaylistIndexPage, PlaylistPage } from '@backstage-community/plugin-playlist';
import type { IdentityApi } from '@backstage/core-plugin-api';
import { ShouldIDeployPage } from 'backstage-plugin-should-i-deploy';
import { DevToolsPage } from '@backstage/plugin-devtools';
import { customDevToolsPage } from './components/devtools/CustomDevToolsPage';
import { CatalogUnprocessedEntitiesPage } from '@backstage/plugin-catalog-unprocessed-entities';
import { devToolsAdministerPermission } from '@backstage/plugin-devtools-common';

import { timeSaverPermission } from '@tduniec/backstage-plugin-time-saver-common';
import { TimeSaverPage } from '@tduniec/backstage-plugin-time-saver';

import { OpsgeniePage } from '@k-phoen/backstage-plugin-opsgenie';
import { opsgenieAccessPermission } from './permissions/opsgeniePermission';
import { MspDashboardPage } from '@internal/backstage-plugin-msp-dashboard';

import { RBACRoot } from '@spotify/backstage-plugin-rbac';
import { TechInsightsScorecardPage } from '@backstage-community/plugin-tech-insights';
// import { SoundcheckRoutingPage } from '@spotify/backstage-plugin-soundcheck';

// import { SkillExchangePage } from '@spotify/backstage-plugin-skill-exchange';

// import { InsightsPage, insightsPlugin } from '@spotify/backstage-plugin-insights';

import {
  feedbackPlugin,
  GlobalFeedbackPage,
  OpcFeedbackComponent,
} from '@janus-idp/backstage-plugin-feedback';
import Draggable from "./components/draggable/Draggable";
import { RelationsCatalogGraphPage } from '@dweber019/backstage-plugin-relations';
import { UnifiedThemeProvider, themes as builtinThemes,} from '@backstage/theme';
import { BitbucketFrontendPage } from '@internal/backstage-plugin-bitbucket-frontend';

const app = createApp({
  components: {
    SignInPage: props => {
      return (
        <SignInPage
          {...props}
          auto
          providers={[
            {
              id: 'microsoft-auth-provider',
              title: "Microsoft",
              message: 'Sign in using Microsoft',
              apiRef: microsoftAuthApiRef
            }
          ]}
          title="Sign In to Backstage"
          align="center"
          onSignInSuccess={async (identityApi: IdentityApi) => {
            props.onSignInSuccess(identityApi);
          }}
        />
      )
    },
  },
  plugins: [shortcutsPlugin],
  apis,
  bindRoutes({ bind }) {
    bind(catalogPlugin.externalRoutes, {
      createComponent: scaffolderPlugin.routes.root,
      viewTechDoc: techdocsPlugin.routes.docRoot,
    });
    bind(apiDocsPlugin.externalRoutes, {
      registerApi: catalogImportPlugin.routes.importPage,
    });
    bind(scaffolderPlugin.externalRoutes, {
      registerComponent: catalogImportPlugin.routes.importPage,
    });
    bind(orgPlugin.externalRoutes, {
      catalogIndex: catalogPlugin.routes.catalogIndex,
    });
    // bind(catalogGraphPlugin.externalRoutes, {
    // catalogEntity: catalogPlugin.routes.catalogEntity,
    // });
    // bind(insightsPlugin.externalRoutes, {
    //   searchPageRouteRef: searchPlugin.routes.root,
    // });
    // Bind techdocs root route to feedback plugin externalRoute.viewDocs to add "View Docs" link in opc-feedback component
    bind(feedbackPlugin.externalRoutes, {
      viewDocs: techdocsPlugin.routes.root,
    });
  },
  themes: [
    {
      id: 'dark-theme', // Unique identifier for the theme
      title: 'Dark Theme', // Display name for the theme
      variant: 'dark', // Theme variant (light or dark)
      Provider: ({children}) => (<UnifiedThemeProvider theme={builtinThemes.dark} children={children} />),
    }
  ]
});

const techDocsTabsConfig: TabsConfig = [
  {
    label: 'How to Use Synthesis Distilled',
    panels: [
      {
        title: 'Golden Path',
        description: 'Documentation about how to use Backstage',
        panelType: 'DocsCardGrid',
        filterPredicate: (entity: Entity) =>
          entity?.metadata?.name === 'synthesis-distilled'
      },
    ],
  },
  {
    label: 'Component Documentation',
    panels: [
      {
        title: 'Terraform Modules',
        description: 'Documentation for each of the Terraform Modules in the Platform',
        panelType: 'DocsTable',
        filterPredicate: (entity: Entity) => {
          // entity?.metadata.tags?.includes('terraform') && entity?.spec?.type === 'library'
          // entity?.metadata.tags?.includes('terraform') returns either true or undefined. Added a classic check to ensure boolean is returned
          if (entity?.metadata.tags?.includes('terraform') && entity?.spec?.type === 'library') {
            return true;
          }
          return false;
        }
      },
    ],
  },
  {
    label: 'Architecture',
    panels: [
      {
        title: 'Golden Path',
        description: 'Synthesis best practices and architecture guidance',
        panelType: 'DocsCardGrid',
        filterPredicate: (entity: Entity) => {
          if (entity?.metadata?.tags?.includes('architecture') && entity?.spec?.type === 'architecture') {
            return true;
          }
          return false;
        }
      },
    ],
  }
];

const routes = (
  <FlatRoutes>
    <Route path="/" element={<HomepageCompositionRoot />}>
      <HomePage />
    </Route>;
    <Route path="/rbac" element={<RBACRoot />} />
    <Route path="/xkcd" element={<XkcdPage />} />
    <Route path="/" element={<Navigate to="catalog" />} />
    <Route path="/catalog" element={<CatalogIndexPage />} />
    <Route
      path="/catalog/:namespace/:kind/:name"
      element={<CatalogEntityPage />}
    >
      {entityPage}
    </Route>
    <Route
      path="/docs"
      element={<TechDocsCustomHome tabsConfig={techDocsTabsConfig} />}
    />
    <Route
      path="/docs/:namespace/:kind/:name/*"
      element={<TechDocsReaderPage />}
    >
      <TechDocsAddons>
        <ReportIssue />
      </TechDocsAddons>
    </Route>
    <Route path="/cost-insights" element={<CostInsightsPage />} />
    <Route path="/create" element={<ScaffolderPage groups={
      [
        {
          title: 'Cloud Templates',
          filter: entity =>
            entity?.metadata?.tags?.includes('cloud') ?? false,
        },
        {
          title: 'MSP Templates',
          filter: entity =>
            entity?.metadata?.tags?.includes('msp') ?? false,
        },
        {
          title: 'Digital Templates',
          filter: entity =>
            entity?.metadata?.tags?.includes('digital') ?? false,
        },
        {
          title: 'Intelligent Data Templates',
          filter: entity =>
            entity?.metadata?.tags?.includes('intelligent-data') ?? false,
        },
        {
          title: 'Backstage-Related Templates',
          filter: entity =>
            entity?.metadata?.tags?.includes('backstage-utils') ?? false,
        },
      ]
    } />}>
      <ScaffolderFieldExtensions>
        <BitbucketSecretsExtension />
      </ScaffolderFieldExtensions>
    </Route>
    <Route path="/announcements" element={<AnnouncementsPage />} />
    <Route path="/api-docs" element={<ApiExplorerPage />} />
    <Route path="/catalog-graph" element={<RelationsCatalogGraphPage />} />
    <Route
      path="/catalog-import"
      element={
        <RequirePermission permission={catalogEntityCreatePermission}>
          <CatalogImportPage />
        </RequirePermission>
      }
    />
    <Route path="/search" element={<SearchPage />}>
      {searchPage}
    </Route>
    <Route path="/explore" element={<ExplorePage />} />
    <Route path="/settings" element={<UserSettingsPage />} />
    <Route path="/catalog-graph" element={<CatalogGraphPage />} />
    <Route path="/toolbox" element={<ToolboxPage />} />
    <Route path="/chatgpt" element={<ChatGPTFrontendPage />} />
    <Route path="/playlist" element={<PlaylistIndexPage />} />
    <Route path="/playlist/:playlistId" element={<PlaylistPage />} />
    <Route path="/should-i-deploy" element={<ShouldIDeployPage />} />
    <Route path="/devtools" element={
      <RequirePermission permission={devToolsAdministerPermission}>
        <DevToolsPage />
      </RequirePermission>
    } >
      {customDevToolsPage}
    </Route>
    <Route
      path="/catalog-unprocessed-entities"
      element={<CatalogUnprocessedEntitiesPage />}
    />;
    <Route
      path="/time-saver"
      element={
        <RequirePermission permission={timeSaverPermission}>
          <TimeSaverPage />
        </RequirePermission>
      }
    />
    <Route
      path="/opsgenie"
      element={
        <RequirePermission permission={opsgenieAccessPermission}>
          <OpsgeniePage />
        </RequirePermission>
      }
    />
    <Route path="/msp-dashboard" element={<MspDashboardPage />} />
    {/* <Route
      path='/soundcheck'
      element={<SoundcheckRoutingPage title='SoundCheck' />}
    /> */}
    {/* <Route
      path='/skill-exchange'
      element={<SkillExchangePage enableEmbeds enableHacks enableMentorships />}
    /> */}
    {/* <Route path="/insights" element={<InsightsPage />} /> */}
    {/* Insert this line to add feedback route */}
    <Route path="/feedback" element={<GlobalFeedbackPage />} />
    <Route path="/tech-insights" element={<TechInsightsScorecardPage />} />
    <Route path="/bitbucket-frontend" element={<BitbucketFrontendPage />} />
  </FlatRoutes>
);

const opcFeedback = <OpcFeedbackComponent />;

export default app.createRoot(
  <>
    <AlertDisplay />
    <OAuthRequestDialog />
    <AppRouter>
      <VisitListener />
      <Root>{routes}</Root>
      <Draggable children={opcFeedback} />
    </AppRouter>
  </>,
);
