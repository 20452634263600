import React from 'react'
import Icon from "@mui/material/Icon";
import SonarQube from './assets/sonarqube.svg'

const SonarQubeIcon = () => (
    <Icon>
        <img src={SonarQube} alt='Sona Cube Icon' height="100%" width="100%"/>
    </Icon>
)

export default SonarQubeIcon;