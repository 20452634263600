import React, { ReactNode, useMemo, useState } from 'react';
import Grid from '@mui/material/Grid2';
import {
  EntityApiDefinitionCard,
  EntityConsumedApisCard,
  EntityConsumingComponentsCard,
  EntityHasApisCard,
  EntityProvidedApisCard,
  EntityProvidingComponentsCard,
} from '@backstage/plugin-api-docs';
import {
  EntityAboutCard,
  EntityDependsOnComponentsCard,
  EntityDependsOnResourcesCard,
  EntityHasComponentsCard,
  EntityHasResourcesCard,
  // EntityHasSubcomponentsCard,
  EntityHasSystemsCard,
  EntityLayout,
  EntityLinksCard,
  EntitySwitch,
  EntityOrphanWarning,
  EntityProcessingErrorsPanel,
  isComponentType,
  isKind,
  hasCatalogProcessingErrors,
  isOrphan,
} from '@backstage/plugin-catalog';
import {
  isGithubActionsAvailable,
  EntityGithubActionsContent,
} from '@backstage-community/plugin-github-actions';
import {
  EntityUserProfileCard,
  EntityGroupProfileCard,
  EntityMembersListCard,
  EntityOwnershipCard,
} from '@backstage/plugin-org';
import { EntityTechdocsContent } from '@backstage/plugin-techdocs';
// import { InfoCard } from '@backstage/core-components';

import {
  // Direction,
  EntityCatalogGraphCard,
} from '@backstage/plugin-catalog-graph';
/* import {
  RELATION_OWNER_OF,
  RELATION_OWNED_BY,
  RELATION_API_CONSUMED_BY,
  RELATION_API_PROVIDED_BY,
  RELATION_CONSUMES_API,
  RELATION_DEPENDENCY_OF,
  RELATION_DEPENDS_ON,
  RELATION_HAS_PART,
  RELATION_PART_OF,
  RELATION_PROVIDES_API,
} from '@backstage/catalog-model';*/

import {
  EntityDatadogContent,
  EntityDatadogGraphCard,
  isDatadogGraphAvailable,
} from '@roadiehq/backstage-plugin-datadog';

import { TechDocsAddons } from '@backstage/plugin-techdocs-react';
import { ReportIssue } from '@backstage/plugin-techdocs-module-addons-contrib';
import { EntityTodoContent } from '@backstage-community/plugin-todo';
import { EntityCicdStatisticsContent } from '@backstage-community/plugin-cicd-statistics';
// import { RELATION_SCAFFOLDED_BY, RELATION_SCAFFOLDER_OF } from '../../../../backend/src/plugins/custom-relationships/customRelationshipTypes';
import { EntityPlaylistDialog } from '@backstage-community/plugin-playlist';
import PlaylistAddIcon from '@mui/icons-material/PlaylistAdd';
import { EntityChangelogContent, EntityChangelogCard } from '@rsc-labs/backstage-changelog-plugin';
import { conventionalChangelogParser } from '../../helpers/changelogparser';
import { EntityGithubInsightsContent } from '@roadiehq/backstage-plugin-github-insights';
import { EntityGithubPullRequestsContent, EntityGithubPullRequestsOverviewCard } from '@roadiehq/backstage-plugin-github-pull-requests';
import { EntityGithubDependabotContent, DependabotAlertsWidget, EntitySecurityInsightsContent, EntitySecurityInsightsCard } from '@roadiehq/backstage-plugin-security-insights';
import {
  EntityArgoCDOverviewCard,
  isArgocdAvailable
} from '@roadiehq/backstage-plugin-argo-cd';
import { EntityTechInsightsScorecardContent } from '@backstage-community/plugin-tech-insights';
import { InfracostOverviewPage, isInfracostAvailable } from '@veecode-platform/backstage-plugin-infracost';
import { EntityTerraformContent,EntityTerraformLatestRunCard } from '@globallogicuki/backstage-plugin-terraform';
import { ReadmeCard } from '@axis-backstage/plugin-readme';
import { EntityRelationsCard, EntityRelationsCatalogGraphCard } from '@dweber019/backstage-plugin-relations';

// import {
//   EntitySoundcheckContent,
//   EntitySoundcheckCard,
//   GroupSoundcheckContent
// } from '@spotify/backstage-plugin-soundcheck';

const EntityLayoutWrapper = (props: { children?: ReactNode }) => {
  const [playlistDialogOpen, setPlaylistDialogOpen] = useState(false);

  const extraMenuItems = useMemo(() => {
    return [
      {
        title: 'Add to playlist',
        Icon: PlaylistAddIcon,
        onClick: () => setPlaylistDialogOpen(true),
      },
    ];
  }, []);

  return (
    <>
      <EntityLayout UNSTABLE_extraContextMenuItems={extraMenuItems}>
        {props.children}
      </EntityLayout>
      <EntityPlaylistDialog
        open={playlistDialogOpen}
        onClose={() => setPlaylistDialogOpen(false)}
      />
    </>
  );
};
import { EntitySonarQubeCard } from '@backstage-community/plugin-sonarqube';
import { EntityFeedbackPage } from '@janus-idp/backstage-plugin-feedback';
import { PullRequestFetch } from '@internal/backstage-plugin-bitbucket-frontend';
// import { SkillExchangeUserEntitySkillsCard } from '@spotify/backstage-plugin-skill-exchange';

// const RELATION_SCAFFOLDED_BY = "scaffolder";
// const RELATION_SCAFFOLDER_OF = "scaffolderOf";

const relationLabels = [
  {
    name: 'applicationOwnerOf',
    label: 'Application owner of',
  },
  {
    name: 'applicationOwnedBy',
    label: 'Application owner',
  },
  {
    name: 'teamIsResponsibleFor',
    label: 'Responsible For',
  },
  {
    name: 'responsibilityOf',
    label: 'Responsible Team',
  },
  {
    name: 'managerOf',
    label: 'Manager Of',
  },
  {
    name: 'managedBy',
    label: 'Managed By',
  },
  {
    name: 'hasEnviroment',
    label: 'Has Enviroment',
  },
  {
    name: 'belongsTo',
    label: 'Belongs To',
  },
  {
    name: 'scaffolderOf',
    label: 'Scaffolder of'
  },
  {
    name: 'scaffolder',
    label: 'Scaffolded by'
  }
];

const techdocsContent = (
  <EntityTechdocsContent>
    <TechDocsAddons>
      <ReportIssue />
    </TechDocsAddons>
  </EntityTechdocsContent>
);

const cicdContent = (
  // This is an example of how you can implement your company's logic in entity page.
  // You can for example enforce that all components of type 'service' should use GitHubActions
  (<EntitySwitch>
    <EntitySwitch.Case if={isGithubActionsAvailable}>
      <EntityGithubActionsContent />
    </EntitySwitch.Case>
    <EntitySwitch.Case>
      {/* <EmptyState
        title="No CI/CD available for this entity"
        missing="info"
        description="You need to add an annotation to your component if you want to enable CI/CD for it. You can read more about annotations in Backstage by clicking the button below."
        action={
          <Button
            variant="contained"
            color="primary"
            href="https://backstage.io/docs/features/software-catalog/well-known-annotations"
          >
            Read more
          </Button>
        }
      /> */}
      {/* <EntityLayout.Route path="/ci-cd-statistics" title="CI/CD Statistics"> */}
      <EntityCicdStatisticsContent />
      {/* </EntityLayout.Route>; */}
    </EntitySwitch.Case>
  </EntitySwitch>)
);

const entityWarningContent = (
  <>
    <EntitySwitch>
      <EntitySwitch.Case if={isOrphan}>
        <Grid size={{xs:12}}>
          <EntityOrphanWarning />
        </Grid>
      </EntitySwitch.Case>
    </EntitySwitch>

    <EntitySwitch>
      <EntitySwitch.Case if={hasCatalogProcessingErrors}>
        <Grid size={{xs:12}}>
          <EntityProcessingErrorsPanel />
        </Grid>
      </EntitySwitch.Case>
    </EntitySwitch>
  </>
);

const overviewContent = (
  <Grid container spacing={3} alignItems="stretch">
    {entityWarningContent}

    {/* Add to each applicable kind/type of entity as desired */}
    <Grid size={{md:6}}>
      <EntityAboutCard variant="gridItem" />
    </Grid>
    <Grid size={{md:6}}>
      <EntityTerraformLatestRunCard />
    </Grid>
    <EntitySwitch>
      <EntitySwitch.Case if={e => Boolean(isArgocdAvailable(e))}>
        <Grid size={{md:6}}>
          <EntityArgoCDOverviewCard />
        </Grid>
      </EntitySwitch.Case>
    </EntitySwitch>
    <Grid container size={{md:6}} spacing={3}>
      <EntitySwitch>
        <EntitySwitch.Case if={isGithubActionsAvailable}>
          <Grid size={{xs:12}}>
            <EntityGithubPullRequestsOverviewCard variant="gridItem" />
          </Grid>
        </EntitySwitch.Case>
      </EntitySwitch>
      <EntitySwitch>
        <EntitySwitch.Case if={isGithubActionsAvailable}>
          <Grid size={{xs:12}}>
            <EntitySecurityInsightsCard />
          </Grid>
        </EntitySwitch.Case>
        <EntitySwitch.Case if={isGithubActionsAvailable}>
          <Grid size={{xs:12}}>
            <DependabotAlertsWidget />
          </Grid>
        </EntitySwitch.Case>
      </EntitySwitch>
    </Grid>
    <Grid size={{xs:12}}>
      <EntityChangelogCard parser={conventionalChangelogParser} />
    </Grid>
    <Grid size={{md:6}}>
      <EntitySonarQubeCard variant="gridItem" />
    </Grid>
    <EntitySwitch>
      <EntitySwitch.Case if={isDatadogGraphAvailable}>
        <Grid>
          <EntityDatadogGraphCard />
        </Grid>
      </EntitySwitch.Case>
    </EntitySwitch>
    <Grid size={{md:6, xs:12}}>
      <ReadmeCard />
    </Grid>
    <Grid size={{md:6, xs:12}}>
        <EntityRelationsCard relations={relationLabels} />
    </Grid>
    {/* <Grid item md={6} xs={12}>
      <EntitySoundcheckCard />
    </Grid> */}
  </Grid>
);

const serviceEntityPage = (
  <EntityLayout>
    {/* Add to each applicable kind/type of entity as desired */}
    <EntityLayout.Route path="/" title="Overview">
      {overviewContent}
    </EntityLayout.Route>
    <EntityLayout.Route path="/feedback" title="Feedback">
      <EntityFeedbackPage />
    </EntityLayout.Route>
    <EntityLayout.Route path="/changelog" title="Changelog">
      <EntityChangelogContent parser={conventionalChangelogParser} />
    </EntityLayout.Route>
    <EntityLayout.Route path="/ci-cd" title="CI/CD">
      {cicdContent}
    </EntityLayout.Route>
    <EntityLayout.Route path="/code-insights" title="Code Insights" if={isGithubActionsAvailable}>
      <EntityGithubInsightsContent />
    </EntityLayout.Route>
    <EntityLayout.Route path="/pull-requests" title="Pull Requests" if={isGithubActionsAvailable}>
      <EntityGithubPullRequestsContent />
    </EntityLayout.Route>
    <EntityLayout.Route path="/security-insights" title="Security Insights" if={isGithubActionsAvailable}>
      <EntitySecurityInsightsContent />
    </EntityLayout.Route>
    <EntityLayout.Route path="/dependabot" title="Dependabot" if={isGithubActionsAvailable} >
      <EntityGithubDependabotContent />
    </EntityLayout.Route>
    <EntityLayout.Route path="/api" title="API">
      <Grid container spacing={3} alignItems="stretch">
        <Grid size={{md:6}}>
          <EntityProvidedApisCard />
        </Grid>
        <Grid size={{md:6}}>
          <EntityConsumedApisCard />
        </Grid>
      </Grid>
    </EntityLayout.Route>
    <EntityLayout.Route path="/dependencies" title="Dependencies">
      <Grid container spacing={3} alignItems="stretch">
        <Grid size={{md:6}}>
          <EntityDependsOnComponentsCard variant="gridItem" />
        </Grid>
        <Grid size={{md:6}}>
          <EntityDependsOnResourcesCard variant="gridItem" />
        </Grid>
      </Grid>
    </EntityLayout.Route>
    <EntityLayout.Route path="/docs" title="Docs">
      {techdocsContent}
    </EntityLayout.Route>
    <EntityLayout.Route path="/datadog" title="Datadog">
      <EntityDatadogContent />
    </EntityLayout.Route>
    <EntityLayout.Route path="/diagram" title="Diagram">
      <EntityRelationsCatalogGraphCard variant="gridItem" height={400} />
    </EntityLayout.Route>
    <EntityLayout.Route path="/tech-insights" title="Scorecards">
    <EntityTechInsightsScorecardContent
        title="Tech Insights"
        description="Get an overview of the Spotify tech landscape and its health"
      />
    </EntityLayout.Route>
    <EntityLayout.Route path="/terraform" title="Terraform">
      <EntityTerraformContent />
    </EntityLayout.Route>
    <EntityLayout.Route path="/readme" title="README">
      <ReadmeCard variant="fullHeight" />
    </EntityLayout.Route> 
    <EntityLayout.Route path="/bitbucket" title="Bitbucket">
      <PullRequestFetch/>
    </EntityLayout.Route>
    {/* <EntityLayout.Route path='/soundcheck' title='Soundcheck'>
      <EntitySoundcheckContent />
    </EntityLayout.Route> */}
  </EntityLayout>
);

const websiteEntityPage = (
  <EntityLayout>
    <EntityLayout.Route path="/" title="Overview">
      {overviewContent}
    </EntityLayout.Route>
    <EntityLayout.Route path="/feedback" title="Feedback">
      <EntityFeedbackPage />
    </EntityLayout.Route>
    <EntityLayout.Route path="/changelog" title="Changelog">
      <EntityChangelogContent parser={conventionalChangelogParser} />
    </EntityLayout.Route>
    <EntityLayout.Route path="/ci-cd" title="CI/CD">
      {cicdContent}
    </EntityLayout.Route>
    <EntityLayout.Route path="/code-insights" title="Code Insights" if={isGithubActionsAvailable}>
      <EntityGithubInsightsContent />
    </EntityLayout.Route>
    <EntityLayout.Route path="/pull-requests" title="Pull Requests" if={isGithubActionsAvailable}>
      <EntityGithubPullRequestsContent />
    </EntityLayout.Route>
    <EntityLayout.Route path="/security-insights" title="Security Insights" if={isGithubActionsAvailable}>
      <EntitySecurityInsightsContent />
    </EntityLayout.Route>
    <EntityLayout.Route path="/dependabot" title="Dependabot" if={isGithubActionsAvailable} >
      <EntityGithubDependabotContent />
    </EntityLayout.Route>
    <EntityLayout.Route path="/dependencies" title="Dependencies">
      <Grid container spacing={3} alignItems="stretch">
        <Grid size={{md:6}}>
          <EntityDependsOnComponentsCard variant="gridItem" />
        </Grid>
        <Grid size={{md:6}}>
          <EntityDependsOnResourcesCard variant="gridItem" />
        </Grid>
      </Grid>
    </EntityLayout.Route>
    <EntityLayout.Route path="/docs" title="Docs">
      {techdocsContent}
    </EntityLayout.Route>
    <EntityLayout.Route path="/datadog" title="Datadog">
      <EntityDatadogContent />
    </EntityLayout.Route>
    <EntityLayout.Route path="/diagram" title="Diagram">
      <EntityRelationsCatalogGraphCard variant="gridItem" height={400} />
    </EntityLayout.Route>
    <EntityLayout.Route path="/tech-insights" title="Scorecards">
    <EntityTechInsightsScorecardContent
        title="Tech Insights"
        description="Get an overview of the Spotify tech landscape and its health"
        checksId={['hasOwner', 'hasGroupOwner', 'hasTitle', 'hasDescription', 'hasTags', 'hasTechDocsAnnotation']}
      />
    </EntityLayout.Route>
    <EntityLayout.Route if={isInfracostAvailable} path="/infracost" title="Infracost">
       <InfracostOverviewPage/>
    </EntityLayout.Route>
    <EntityLayout.Route path="/terraform" title="Terraform">
      <EntityTerraformContent />
    </EntityLayout.Route>
    <EntityLayout.Route path="/readme" title="README">
      <ReadmeCard variant="fullHeight" />
    </EntityLayout.Route>
    <EntityLayout.Route path="/bitbucket" title="Bitbucket">
      <PullRequestFetch/>
    </EntityLayout.Route>
    {/* <EntityLayout.Route path='/soundcheck' title='Soundcheck'>
      <EntitySoundcheckContent />
    </EntityLayout.Route> */}
  </EntityLayout>
);

const customerEntityPage = (
  <EntityLayout>
    <EntityLayout.Route path="/" title="Overview">
      {overviewContent}
    </EntityLayout.Route>
    <EntityLayout.Route path="/feedback" title="Feedback">
      <EntityFeedbackPage />
    </EntityLayout.Route>
    <EntityLayout.Route path="/ci-cd" title="CI/CD">
      {cicdContent}
    </EntityLayout.Route>
    <EntityLayout.Route path="/code-insights" title="Code Insights" if={isGithubActionsAvailable}>
      <EntityGithubInsightsContent />
    </EntityLayout.Route>
    <EntityLayout.Route path="/pull-requests" title="Pull Requests" if={isGithubActionsAvailable}>
      <EntityGithubPullRequestsContent />
    </EntityLayout.Route>
    <EntityLayout.Route path="/security-insights" title="Security Insights" if={isGithubActionsAvailable}>
      <EntitySecurityInsightsContent />
    </EntityLayout.Route>
    <EntityLayout.Route path="/dependabot" title="Dependabot" if={isGithubActionsAvailable} >
      <EntityGithubDependabotContent />
    </EntityLayout.Route>
    <EntityLayout.Route path="/dependencies" title="Dependencies">
      <Grid container spacing={3} alignItems="stretch">
        <Grid size={{md:6}}>
          <EntityDependsOnComponentsCard variant="gridItem" />
        </Grid>
        <Grid size={{md:6}}>
          <EntityDependsOnResourcesCard variant="gridItem" />
        </Grid>
      </Grid>
    </EntityLayout.Route>
    <EntityLayout.Route path="/diagram" title="Diagram">
      <EntityRelationsCatalogGraphCard variant="gridItem" height={400} />
    </EntityLayout.Route>
    <EntityLayout.Route path="/docs" title="Docs">
      {techdocsContent}
    </EntityLayout.Route>
    <EntityLayout.Route path="/terraform" title="Terraform">
      <EntityTerraformContent />
    </EntityLayout.Route>
    <EntityLayout.Route path="/readme" title="README">
      <ReadmeCard variant="fullHeight" />
    </EntityLayout.Route>
    <EntityLayout.Route path="/bitbucket" title="Bitbucket">
      <PullRequestFetch/>
    </EntityLayout.Route>
    {/* <EntityLayout.Route path='/soundcheck' title='Soundcheck'>
      <EntitySoundcheckContent />
    </EntityLayout.Route> */}
  </EntityLayout>
);

const libraryEntityPage = (
  <EntityLayout>
    <EntityLayout.Route path="/" title="Overview">
      {overviewContent}
    </EntityLayout.Route>
    <EntityLayout.Route path="/feedback" title="Feedback">
      <EntityFeedbackPage />
    </EntityLayout.Route>
    <EntityLayout.Route path="/changelog" title="Changelog">
      <EntityChangelogContent parser={conventionalChangelogParser} />
    </EntityLayout.Route>
    <EntityLayout.Route path="/ci-cd" title="CI/CD">
      {cicdContent}
    </EntityLayout.Route>
    <EntityLayout.Route path="/code-insights" title="Code Insights" if={isGithubActionsAvailable}>
      <EntityGithubInsightsContent />
    </EntityLayout.Route>
    <EntityLayout.Route path="/pull-requests" title="Pull Requests" if={isGithubActionsAvailable} >
      <EntityGithubPullRequestsContent />
    </EntityLayout.Route>
    <EntityLayout.Route path="/security-insights" title="Security Insights" if={isGithubActionsAvailable}>
      <EntitySecurityInsightsContent />
    </EntityLayout.Route>
    <EntityLayout.Route path="/dependabot" title="Dependabot" if={isGithubActionsAvailable} >
      <EntityGithubDependabotContent />
    </EntityLayout.Route>
    <EntityLayout.Route path="/dependencies" title="Dependencies">
      <Grid container spacing={3} alignItems="stretch">
        <Grid size={{md:6}}>
          <EntityDependsOnComponentsCard variant="gridItem" />
        </Grid>
        <Grid size={{md:6}}>
          <EntityDependsOnResourcesCard variant="gridItem" />
        </Grid>
      </Grid>
    </EntityLayout.Route>
    <EntityLayout.Route path="/docs" title="Docs">
      {techdocsContent}
    </EntityLayout.Route>
    <EntityLayout.Route path="/diagram" title="Diagram">
      <EntityRelationsCatalogGraphCard variant="gridItem" height={400} />
    </EntityLayout.Route>
    <EntityLayout.Route path="/tech-insights" title="Scorecards">
      <EntityTechInsightsScorecardContent
        title="Tech Insights"
        description="Get an overview of the Spotify tech landscape and its health"
        checksId={['hasOwner', 'hasGroupOwner', 'hasTitle', 'hasDescription', 'hasTags', 'hasTechDocsAnnotation']}
      />
    </EntityLayout.Route>
    <EntityLayout.Route if={isInfracostAvailable} path="/infracost" title="Infracost">
       <InfracostOverviewPage/>
    </EntityLayout.Route>
    <EntityLayout.Route path="/terraform" title="Terraform">
      <EntityTerraformContent />
    </EntityLayout.Route>
    <EntityLayout.Route path="/readme" title="README">
      <ReadmeCard variant="fullHeight" />
    </EntityLayout.Route>
    <EntityLayout.Route path="/bitbucket" title="Bitbucket">
      <PullRequestFetch/>
    </EntityLayout.Route>
    {/* <EntityLayout.Route path='/soundcheck' title='Soundcheck'>
      <EntitySoundcheckContent />
    </EntityLayout.Route> */}
  </EntityLayout>
);

/**
 * NOTE: This page is designed to work on small screens such as mobile devices.
 * This is based on Material UI Grid. If breakpoints are used, each grid item must set the `xs` prop to a column size or to `true`,
 * since this does not default. If no breakpoints are used, the items will equitably share the available space.
 * https://material-ui.com/components/grid/#basic-grid.
 */

const defaultEntityPage = (
  <EntityLayoutWrapper>
    <EntityLayout.Route path="/" title="Overview">
      {overviewContent}
    </EntityLayout.Route>

    <EntityLayout.Route path="/docs" title="Docs">
      {techdocsContent}
    </EntityLayout.Route>
    <EntityLayout.Route path="/todo" title="Todo">
      <EntityTodoContent />
    </EntityLayout.Route>
    <EntityLayout.Route path="/terraform" title="Terraform">
      <EntityTerraformContent />
    </EntityLayout.Route>
    <EntityLayout.Route path="/readme" title="README">
      <ReadmeCard variant="fullHeight" />
    </EntityLayout.Route>
    {/* <EntityLayout.Route path='/soundcheck' title='Soundcheck'>
      <EntitySoundcheckContent />
    </EntityLayout.Route> */}
  </EntityLayoutWrapper>
);

const mspCustomerEntityPage = (
  <EntityLayout>
    <EntityLayout.Route path="/" title="Overview">
      {overviewContent}
    </EntityLayout.Route>
    <EntityLayout.Route path="/feedback" title="Feedback">
      <EntityFeedbackPage />
    </EntityLayout.Route>
    <EntityLayout.Route path="/ci-cd" title="CI/CD">
      {cicdContent}
    </EntityLayout.Route>
    <EntityLayout.Route path="/code-insights" title="Code Insights" if={isGithubActionsAvailable}>
      <EntityGithubInsightsContent />
    </EntityLayout.Route>
    <EntityLayout.Route path="/pull-requests" title="Pull Requests" if={isGithubActionsAvailable}>
      <EntityGithubPullRequestsContent />
    </EntityLayout.Route>
    <EntityLayout.Route path="/security-insights" title="Security Insights" if={isGithubActionsAvailable}>
      <EntitySecurityInsightsContent />
    </EntityLayout.Route>
    <EntityLayout.Route path="/dependabot" title="Dependabot" if={isGithubActionsAvailable} >
      <EntityGithubDependabotContent />
    </EntityLayout.Route>
    <EntityLayout.Route path="/dependencies" title="Dependencies">
      <Grid container spacing={3} alignItems="stretch">
        <Grid size={{md:6}}>
          <EntityDependsOnComponentsCard variant="gridItem" />
        </Grid>
        <Grid size={{md:6}}>
          <EntityDependsOnResourcesCard variant="gridItem" />
        </Grid>
      </Grid>
    </EntityLayout.Route>
    <EntityLayout.Route path="/docs" title="Docs">
      {techdocsContent}
    </EntityLayout.Route>
    <EntityLayout.Route path="/diagram" title="Diagram">
      <EntityRelationsCatalogGraphCard variant="gridItem" height={400} />
    </EntityLayout.Route>
    <EntityLayout.Route path="/terraform" title="Terraform">
      <EntityTerraformContent />
    </EntityLayout.Route>
    <EntityLayout.Route path="/readme" title="README">
      <ReadmeCard variant="fullHeight" />
    </EntityLayout.Route>
    <EntityLayout.Route path="/bitbucket" title="Bitbucket">
      <PullRequestFetch/>
    </EntityLayout.Route>
    {/* <EntityLayout.Route path='/soundcheck' title='Soundcheck'>
      <EntitySoundcheckContent />
    </EntityLayout.Route> */}
  </EntityLayout>
);

const mspEnvironmentPage = (
  <EntityLayout>
    <EntityLayout.Route path="/" title="Overview">
      {overviewContent}
    </EntityLayout.Route>
    <EntityLayout.Route path="/feedback" title="Feedback">
      <EntityFeedbackPage />
    </EntityLayout.Route>
    <EntityLayout.Route path="/ci-cd" title="CI/CD">
      {cicdContent}
    </EntityLayout.Route>
    <EntityLayout.Route path="/dependencies" title="Dependencies">
      <Grid container spacing={3} alignItems="stretch">
        <Grid size={{md:6}}>
          <EntityDependsOnComponentsCard variant="gridItem" />
        </Grid>
        <Grid size={{md:6}}>
          <EntityDependsOnResourcesCard variant="gridItem" />
        </Grid>
      </Grid>
    </EntityLayout.Route>
    <EntityLayout.Route path="/docs" title="Docs">
      {techdocsContent}
    </EntityLayout.Route>
    <EntityLayout.Route path="/diagram" title="Diagram">
      <EntityRelationsCatalogGraphCard variant="gridItem" height={400} />
    </EntityLayout.Route>
    <EntityLayout.Route path="/terraform" title="Terraform">
      <EntityTerraformContent />
    </EntityLayout.Route>
    <EntityLayout.Route path="/datadog" title="Datadog Dashboards">
      <EntityDatadogContent />
    </EntityLayout.Route>
    <EntityLayout.Route path="/readme" title="README">
      <ReadmeCard variant="fullHeight" />
    </EntityLayout.Route>
    <EntityLayout.Route path="/bitbucket" title="Bitbucket">
      <PullRequestFetch/>
    </EntityLayout.Route>
    {/* <EntityLayout.Route path='/soundcheck' title='Soundcheck'>
      <EntitySoundcheckContent />
    </EntityLayout.Route> */}
  </EntityLayout>
);

const componentPage = (
  <EntitySwitch>
    <EntitySwitch.Case if={isComponentType('service')}>
      {serviceEntityPage}
    </EntitySwitch.Case>

    <EntitySwitch.Case if={isComponentType('website')}>
      {websiteEntityPage}
    </EntitySwitch.Case>

    <EntitySwitch.Case if={isComponentType('library')}>
      {libraryEntityPage}
    </EntitySwitch.Case>
    <EntitySwitch.Case if={isComponentType('customer')}>
      {customerEntityPage}
    </EntitySwitch.Case>
    <EntitySwitch.Case if={isComponentType('mspCustomer')}>
      {mspCustomerEntityPage}
    </EntitySwitch.Case>
    <EntitySwitch.Case if={isComponentType('mspEnvironment')}>
      {mspEnvironmentPage}
    </EntitySwitch.Case>
    <EntitySwitch.Case>{defaultEntityPage}</EntitySwitch.Case>
  </EntitySwitch>
);

const apiPage = (
  <EntityLayout>
    <EntityLayout.Route path="/" title="Overview">
      <Grid container spacing={3}>
        {entityWarningContent}
        <Grid size={{md:6}}>
          <EntityAboutCard />
        </Grid>
        <Grid size={{md:6, xs:12}}>
          <EntityCatalogGraphCard variant="gridItem" height={400} />
        </Grid>
        <Grid size={{md:4, xs:12}}>
          <EntityLinksCard />
        </Grid>
        <Grid container size={{md:12}}>
          <Grid size={{md:6}}>
            <EntityProvidingComponentsCard />
          </Grid>
          <Grid size={{md:6}}>
            <EntityConsumingComponentsCard />
          </Grid>
        </Grid>
      </Grid>
    </EntityLayout.Route>

    <EntityLayout.Route path="/definition" title="Definition">
      <Grid container spacing={3}>
        <Grid size={{
          xs:12}}>
          <EntityApiDefinitionCard />
        </Grid>
      </Grid>
    </EntityLayout.Route>
    <EntityLayout.Route if={isInfracostAvailable} path="/infracost" title="Infracost">
       <InfracostOverviewPage/>
    </EntityLayout.Route>
    <EntityLayout.Route path="/terraform" title="Terraform">
      <EntityTerraformContent />
    </EntityLayout.Route>
    <EntityLayout.Route path="/readme" title="README">
      <ReadmeCard variant="fullHeight" />
    </EntityLayout.Route>
    {/* <EntityLayout.Route path='/soundcheck' title='Soundcheck'>
      <EntitySoundcheckContent />
    </EntityLayout.Route> */}
  </EntityLayout>
);

const userPage = (
  <EntityLayout>
    <EntityLayout.Route path="/" title="Overview">
      <Grid container spacing={3}>
        {entityWarningContent}

        <Grid size={{md:6, xs:12}}>
          <EntityUserProfileCard variant="gridItem" />
        </Grid>
        <Grid size={{md:6, xs:12}}>
          <EntityOwnershipCard variant="gridItem" />
        </Grid>
        {/* <Grid item xs={12} md={6}>
          <SkillExchangeUserEntitySkillsCard /> 
        </Grid> */}
        <Grid size={{md:6, xs:12}}>
          <EntityRelationsCard relations={relationLabels} />
        </Grid>
      </Grid>
    </EntityLayout.Route>
    <EntityLayout.Route path='/diagram' title='Diagram'>
        <EntityRelationsCatalogGraphCard variant="gridItem" height={500} />
    </EntityLayout.Route>
  </EntityLayout>
  
);

const groupPage = (
  <EntityLayout>
    <EntityLayout.Route path="/" title="Overview">
      <Grid container spacing={3}>
        {entityWarningContent}

        <Grid size={{md:6, xs:12}}>
          <EntityGroupProfileCard variant="gridItem" />
        </Grid>
        <Grid size={{md:6, xs:12}}>
          <EntityOwnershipCard variant="gridItem" />
        </Grid>
        <Grid size={{md:6, xs:12}}>
          <EntityMembersListCard />
        </Grid>
        <Grid size={{md:6, xs:12}}>
          <EntityRelationsCard relations={relationLabels} />
        </Grid>
      </Grid>
    </EntityLayout.Route>
    <EntityLayout.Route path="/tech-insights" title="Scorecards">
      <EntityTechInsightsScorecardContent
        title="Tech Insights"
        description="Get an overview of the Spotify tech landscape and its health"
        checksId={['hasOwner', 'hasGroupOwner', 'hasTitle', 'hasDescription', 'hasTags', 'hasTechDocsAnnotation']}
      />
    </EntityLayout.Route>
    <EntityLayout.Route path='/diagram' title="Diagram">
      <EntityRelationsCatalogGraphCard variant="gridItem" height={500}/>
    </EntityLayout.Route>
    {/* <EntityLayout.Route path='/soundcheck' title='Soundcheck'>
      <GroupSoundcheckContent />
    </EntityLayout.Route> */}
  </EntityLayout>
);

const systemPage = (
  <EntityLayout>
    <EntityLayout.Route path="/" title="Overview">
      <Grid container spacing={3} alignItems="stretch">
        {entityWarningContent}
        <Grid size={{md:6}}>
          <EntityAboutCard variant="gridItem" />
        </Grid>
        <Grid size={{md:6, xs:12}}>
          <EntityCatalogGraphCard variant="gridItem" height={400} />
        </Grid>
        <Grid size={{md:4, xs:12}}>
          <EntityLinksCard />
        </Grid>
        <Grid size={{md:8}}>
          <EntityHasComponentsCard variant="gridItem" />
        </Grid>
        <Grid size={{md:6}}>
          <EntityHasApisCard variant="gridItem" />
        </Grid>
        <Grid size={{md:6}}>
          <EntityHasResourcesCard variant="gridItem" />
        </Grid>
      </Grid>
    </EntityLayout.Route>
    <EntityLayout.Route path="/diagram" title="Diagram">
      <EntityRelationsCatalogGraphCard variant="gridItem" height={400} />
    </EntityLayout.Route>
    <EntityLayout.Route if={isInfracostAvailable} path="/infracost" title="Infracost">
       <InfracostOverviewPage/>
    </EntityLayout.Route>
    <EntityLayout.Route path="/terraform" title="Terraform">
      <EntityTerraformContent />
    </EntityLayout.Route>
    <EntityLayout.Route path="/readme" title="README">
      <ReadmeCard variant="fullHeight" />
    </EntityLayout.Route>
    {/* <EntityLayout.Route path='/soundcheck' title='Soundcheck'>
      <EntitySoundcheckContent />
    </EntityLayout.Route> */}
  </EntityLayout>
);

const domainPage = (
  <EntityLayout>
    <EntityLayout.Route path="/" title="Overview">
      <Grid container spacing={3} alignItems="stretch">
        {entityWarningContent}
        <Grid size={{md:6}}>
          <EntityAboutCard variant="gridItem" />
        </Grid>
        <Grid size={{md:6, xs:12}}>
          <EntityCatalogGraphCard variant="gridItem" height={400} />
        </Grid>
        <Grid size={{md:6}}>
          <EntityHasSystemsCard variant="gridItem" />
        </Grid>
      </Grid>
    </EntityLayout.Route>
  </EntityLayout>
);

export const entityPage = (
  <EntitySwitch>
    <EntitySwitch.Case if={isKind('component')} children={componentPage} />
    <EntitySwitch.Case if={isKind('api')} children={apiPage} />
    <EntitySwitch.Case if={isKind('group')} children={groupPage} />
    <EntitySwitch.Case if={isKind('user')} children={userPage} />
    <EntitySwitch.Case if={isKind('system')} children={systemPage} />
    <EntitySwitch.Case if={isKind('domain')} children={domainPage} />

    <EntitySwitch.Case>{defaultEntityPage}</EntitySwitch.Case>
  </EntitySwitch>
);
