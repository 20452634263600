import React, {useState, useEffect} from 'react';
import { BitbucketApi } from './BitbucketApi';
import { useApi, configApiRef } from '@backstage/core-plugin-api';
import { PullRequest } from './PullRequest';
import Grid from '@mui/material/Grid2';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import TextField from '@mui/material/TextField';
import Autocomplete from '@mui/material/Autocomplete';
import Stack from '@mui/material/Stack';
import { useEntity } from '@backstage/plugin-catalog-react';
import IconButton from '@mui/material/IconButton';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import Collapse from '@mui/material/Collapse';
import Box from '@mui/material/Box';

function Row(props: { pullRequest: PullRequest}) {
  const { pullRequest } = props;
  const [open, setOpen] = React.useState(false);

  return (
    <React.Fragment>
      <TableRow sx={{ '& > *': { borderBottom: 'unset' } }}>
        <TableCell>
          <IconButton
            aria-label="expand row"
            size="small"
            onClick={() => setOpen(!open)}
          >
            {open ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
          </IconButton>
        </TableCell>
        <TableCell component="th" scope="row">{pullRequest.id}</TableCell>
        <TableCell align="left">{pullRequest.title}</TableCell>
        <TableCell align="left">{pullRequest.state}</TableCell>
        <TableCell align="left">{pullRequest.author}</TableCell>
        <TableCell align="left">{pullRequest.created_on}</TableCell>
        <TableCell align="left">{pullRequest.updated_on}</TableCell>
      </TableRow>
      <TableRow>
        <TableCell style={{ paddingBottom: 0, paddingTop: 0 }} colSpan={6}>
          <Collapse in={open} timeout="auto" unmountOnExit>
            <Box sx={{ margin: 1 }}>
               {pullRequest.description}
            </Box>
          </Collapse>
        </TableCell>
      </TableRow>
    </React.Fragment>
  );
}

export const RequestsTable = () => {
  const {entity} = useEntity();
  const repoName = entity.metadata.name;
  const pullRequestStateOptions: string[]= ['OPEN','MERGED'];
  const [pullRequestState, setPullRequestSate] = useState<string>(pullRequestStateOptions[0]);
  const [pullRequests,setPullRequests] = useState<PullRequest[]>([]);
  const config = useApi(configApiRef);
  const baseUrl = config.getString('backend.baseUrl');
  useEffect(() => {
    const api: BitbucketApi = new BitbucketApi();
    api.fetchPullRequestList(baseUrl,repoName, pullRequestState).then(data => setPullRequests(data));
  }, [baseUrl,pullRequestState,repoName]);
  return (
     
       <Grid container>
            <Grid size={12}>
            <Stack 
              direction="row" 
              spacing={2}  
              sx={{
                justifyContent: "space-between",
                alignItems: "center",
              }}>
                <h3>
                  Pull Request List
                </h3>
                <Autocomplete
                      size="small"
                      disablePortal
                      value={pullRequestState}
                      onChange={(_event: any, newValue: string | null) => {
                        setPullRequestSate(newValue? newValue: 'OPEN');
                      }}
                      options={pullRequestStateOptions}
                      sx={{ width: 200 }}
                      renderInput={(params) => <TextField {...params} label="Filter by State" />}
                    />
            </Stack>
            </Grid>
            
            <Grid size={12}>
            <TableContainer component={Paper}>
          <Table sx={{ minWidth: 650 }} aria-label="simple table">
            <TableHead>
              <TableRow>
                <TableCell />
                <TableCell>Id</TableCell>
                <TableCell align="left">Title</TableCell>
                <TableCell align="left">State</TableCell>
                <TableCell align="left">Author</TableCell>
                <TableCell align="left">Created On</TableCell>
                <TableCell align="left">Updated On</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {pullRequests.map((pullRequestRow) => (
                <Row key={pullRequestRow.id} pullRequest={pullRequestRow} />
              ))}
            </TableBody>
          </Table>
        </TableContainer>
            </Grid>
       </Grid>
      
        
    
  );
};
export const PullRequestFetch = () => {
   return <RequestsTable />;
};