/*
 * Copyright 2021 The Backstage Authors
 *
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 *     http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 */
import Grid from '@mui/material/Grid2';
import {
  // HomePageRandomJoke,
  WelcomeTitle,
  HeaderWorldClock,
  ClockConfig,
  HomePageStarredEntities,
  HomePageToolkit,
  HomePageTopVisited,
  HomePageRecentlyVisited,
  // CustomHomepageGrid,
  // HomePageCompanyLogo,
} from '@backstage/plugin-home';
import { HomePageSearchBar } from '@backstage/plugin-search'
// import {
//   HomePageStackOverflowQuestions 
// } from '@backstage/plugin-stack-overflow';
import { Content, Header, Page } from '@backstage/core-components';
// import { HomePageSearchBar } from '@backstage/plugin-search';
import React from 'react';
// import HomeIcon from '@mui/icons-material/Home';
import { AnnouncementsCard, NewAnnouncementBanner } from '@procore-oss/backstage-plugin-announcements';
import LogoHome from '../Root/LogoHome';
import SonarQubeIcon from '../Root/icons/SonarQubeIcon';
import TerraformIcon from '../Root/icons/TerraformIcon';
import StackOverflowIcon from '../Root/icons/StackoverflowIcon';
import { ShouldIDeployCard } from 'backstage-plugin-should-i-deploy';

const clockConfigs: ClockConfig[] = [
  {
    label: 'South Africa',
    timeZone: 'Africa/Johannesburg',
  },
  {
    label: 'NYC',
    timeZone: 'America/New_York',
  },
  {
    label: 'UTC',
    timeZone: 'UTC',
  },
  {
    label: 'STO',
    timeZone: 'Europe/Stockholm',
  },
  {
    label: 'TYO',
    timeZone: 'Asia/Tokyo',
  },
];

const timeFormat: Intl.DateTimeFormatOptions = {
  hour: '2-digit',
  minute: '2-digit',
  hour12: false,
};

/* const defaultConfig = [
  {
    component: 'CompanyLogo',
    x: 0,
    y: 0,
    width: 12,
    height: 1,
  },
  {
    component: 'WelcomeTitle',
    x: 0,
    y: 1,
    width: 12,
    height: 1,
  },
  {
    component: 'HomePageSearchBar',
    x: 0,
    y: 2,
    width: 12,
    height: 1,
  },
];*/

const tools = [
  {
    url : "https://stackoverflow.com", 
    label : "StackOverflow", 
    icon : <StackOverflowIcon />
  }
]

const important_links = [
  {
    url : "sonarqube.tooling.synthesize.co.za", 
    label : "SonarQube", 
    icon : <SonarQubeIcon />
  },
  {
    url : "terralist.tooling.synthesize.co.za", 
    label : "TerraList", 
    icon : <TerraformIcon />
  }
]

export const HomePage = () => {
  return (
    <Page themeId="home">
      <Header title={<WelcomeTitle />} pageTitleOverride="Home">
        <HeaderWorldClock
          clockConfigs={clockConfigs}
          customTimeFormat={timeFormat}
        />
      </Header>
      {/* <Header title="AnnouncementsCard" /> */}
      <Content>
          <Grid size={{md:12}} >
            <NewAnnouncementBanner />
          </Grid>
          <Grid container justifyContent='center'>
            <LogoHome/>
            <HomePageSearchBar style={{width: "75%", marginBottom: "40px"}} />
          </Grid>
        <Grid container spacing={3} alignItems="stretch">
          <Grid container size={{md:6}}>
            <Grid size={{md:12, xs:12}}>
              <HomePageTopVisited />
            </Grid>
            <Grid size={{md:12, xs:12}}>
              <HomePageRecentlyVisited />
            </Grid>
            <Grid size={{md:4}}>
              <HomePageToolkit tools={important_links} title='Important Links'/>
            </Grid>
            <Grid size={{md:4}}>
              <HomePageToolkit tools={tools}/>
            </Grid>
            <Grid size={{md:4}}>
              <HomePageToolkit tools={[{url : "https://stackoverflow.com", label : "Terralist API Token", icon : null}]} title='Important Docs'/>
            </Grid>
            
          </Grid>
          <Grid size={{md:6}} style={{paddingTop: "0", paddingBottom: "0", marginBottom: "-10px"}}>
            <AnnouncementsCard max={8}/>
          </Grid>
          <Grid size={{md:6, xs:12}}>
            <Grid container direction="column">
              <Grid>
                <ShouldIDeployCard title='Do you want to play a game?' timeZone="Africa/Johannesburg"/>
              </Grid>
            </Grid>
          </Grid>
          <Grid size={{md:12, xs:12}} style={{paddingLeft: "0", marginRight: "15px"}}>
            <HomePageStarredEntities/>
          </Grid>
        </Grid>
      </Content>
    </Page>
  ); 
};