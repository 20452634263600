import React from 'react';
import Grid from '@mui/material/Grid2';
import Paper from '@mui/material/Paper';
import { css } from '@emotion/css';
import { CatalogSearchResultListItem } from '@backstage/plugin-catalog';
import {
  catalogApiRef,
  CATALOG_FILTER_EXISTS,
} from '@backstage/plugin-catalog-react';
import { TechDocsSearchResultListItem } from '@backstage/plugin-techdocs';

import { SearchType } from '@backstage/plugin-search';
import {
  SearchBar,
  SearchFilter,
  SearchResult,
  SearchPagination,
  useSearch,
} from '@backstage/plugin-search-react';
import {
  Content,
  Header,
  Page,
} from '@backstage/core-components';
import { useApi } from '@backstage/core-plugin-api';
import { ToolSearchResultListItem } from '@backstage-community/plugin-explore';
import BuildIcon from '@mui/icons-material/Build';
import MenuBook from '@mui/icons-material/MenuBook'
import DescriptionIcon from '@mui/icons-material/Description';
import RecordVoiceOverIcon from '@mui/icons-material/RecordVoiceOver'; 
import { AnnouncementSearchResultListItem } from '@procore-oss/backstage-plugin-announcements';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';

const SearchPage = () => {
  const { types } = useSearch();
  const catalogApi = useApi(catalogApiRef);

  return (
    <Page themeId="home">
      <Header title="Search" />
      <Content>
        <Grid container spacing={2}>
          <Grid size={{xs:12}}>
            <Paper className={css`padding: 0px 15px 5px 15px;`}>
              <SearchBar />
            </Paper>
          </Grid>
          <Grid size={{xs:3}}>
            <SearchType.Accordion
              name="Result Type"
              defaultValue="software-catalog"
              types={[
                {
                  value: 'software-catalog',
                  name: 'Software Catalog',
                  icon: <MenuBook />,
                },
                {
                  value: 'techdocs',
                  name: 'Documentation',
                  icon: <DescriptionIcon />,
                },
                {
                  value: 'explore',
                  name: 'Explore',
                  icon: <BuildIcon />,
                },
                {
                  value: 'announcements',
                  name: 'Announcements',
                  icon: <RecordVoiceOverIcon />,
                },
              ]}
            />
            <Card>
              {types.includes('techdocs') && (
                <CardContent>
                <SearchFilter.Select
                  label="Entity"
                  name="name"
                  values={async () => {
                    // Return a list of entities which are documented.
                    const { items } = await catalogApi.getEntities({
                      fields: ['metadata.name'],
                      filter: {
                        'metadata.annotations.backstage.io/techdocs-ref':
                          CATALOG_FILTER_EXISTS,
                      },
                    });

                    const names = items.map(entity => entity.metadata.name);
                    names.sort();
                    return names;
                  }}
                />
                </CardContent>
              )}
              <CardContent>
              <SearchFilter.Select
                label="Kind"
                name="kind"
                values={['Component', 'Template']}
              />
              </CardContent>
              <CardContent>
              <SearchFilter.Checkbox
                label="Lifecycle"
                name="lifecycle"
                values={['experimental', 'production']}
              />
              </CardContent>
            </Card>
          </Grid>
          <Grid size={{xs:9}}>
            <SearchPagination />
            <SearchResult>
              <CatalogSearchResultListItem icon={<MenuBook />} />
              <TechDocsSearchResultListItem icon={<DescriptionIcon />} />
              <ToolSearchResultListItem icon={<BuildIcon />} />
              <AnnouncementSearchResultListItem />
            </SearchResult>
          </Grid>
        </Grid>
      </Content>
    </Page>
  );
};

export const searchPage = <SearchPage />;
