import React, { PropsWithChildren } from 'react';
import { css } from '@emotion/css';
import HomeIcon from '@mui/icons-material/Home';
import ExtensionIcon from '@mui/icons-material/Extension';
import LibraryBooks from '@mui/icons-material/LibraryBooks';
import Announcement from '@mui/icons-material/Announcement';
import NextWeekIcon from '@mui/icons-material/NextWeek';
import CreateComponentIcon from '@mui/icons-material/AddCircleOutline';
import LogoFull from './LogoFull';
import LogoIcon from './LogoIcon';
import {
  Settings as SidebarSettings,
  UserSettingsSignInAvatar,
} from '@backstage/plugin-user-settings';
import { SidebarSearchModal } from '@backstage/plugin-search';
import {
  Sidebar,
  sidebarConfig,
  SidebarDivider,
  SidebarGroup,
  SidebarItem,
  SidebarPage,
  SidebarScrollWrapper,
  SidebarSpace,
  useSidebarOpenState,
  Link,
} from '@backstage/core-components';
import MenuIcon from '@mui/icons-material/Menu';
import SearchIcon from '@mui/icons-material/Search';
import LayersIcon from '@mui/icons-material/Layers';
import MoneyIcon from '@mui/icons-material/MonetizationOn';
import BuildIcon from '@mui/icons-material/Build';
import DesktopWindowsIcon from '@mui/icons-material/DesktopWindows';
import { Shortcuts } from '@backstage-community/plugin-shortcuts';
import PlaylistPlayIcon from '@mui/icons-material/PlaylistPlay';
import { timeSaverPermission } from '@tduniec/backstage-plugin-time-saver-common';
import Timelapse from '@mui/icons-material/Timelapse';
import { RequirePermission } from '@backstage/plugin-permission-react';
import EmojiObjectsIcon from '@mui/icons-material/EmojiObjects';
import ReportProblemIcon from '@mui/icons-material/ReportProblem';
import { opsgenieAccessPermission } from '../../permissions/opsgeniePermission';
import { devtoolsAccessPermission } from '../../permissions/devtoolsPermission';
import { mspAccessPermission } from '@internal/backstage-plugin-msp-common';

import { RBACSidebarItem } from '@spotify/backstage-plugin-rbac';

import TextsmsOutlined  from '@mui/icons-material/TextsmsOutlined';

// import DoneAllIcon from '@material-ui/icons/DoneAll';
// import SchoolIcon from '@material-ui/icons/School';

// import { InsightsSidebarItem } from '@spotify/backstage-plugin-insights'
const heightValue = 3*sidebarConfig.logoHeight;
const rootStyle = css`
    width: ${sidebarConfig.drawerWidthOpen}px;
    height: ${heightValue}px;
    display: flex;
    flexFlow: row nowrap;
    alignItems: center;
    marginBottom: -14px;`;
const linkStyle = css`width: ${sidebarConfig.drawerWidthOpen}px; marginLeft: 12px;`;

const SidebarLogo = () => {
  const { isOpen } = useSidebarOpenState();

  return (
    <div className={rootStyle}>
      <Link to="/" underline="none" className={linkStyle} aria-label="Home">
        {isOpen ? <LogoFull /> : <LogoIcon />}
      </Link>
    </div>
  );
};

export const Root = ({ children }: PropsWithChildren<{}>) => (
  <SidebarPage>
    <Sidebar>
      <SidebarLogo />
      <SidebarGroup label="Search" icon={<SearchIcon />} to="/search">
        <SidebarSearchModal />
      </SidebarGroup>
      <SidebarDivider />
      <SidebarGroup label="Menu" icon={<MenuIcon />}>
        {/* Global nav, not org-specific */}
        <SidebarItem icon={HomeIcon} to="catalog" text="Home" />
        <SidebarItem icon={ExtensionIcon} to="api-docs" text="APIs" />
        <SidebarItem icon={LibraryBooks} to="docs" text="Docs" />
        <SidebarItem icon={LayersIcon} to="explore" text="Explore" />
        {/* <SidebarItem icon={SchoolIcon} to='skill-exchange' text='Skill Exchange' /> */}
        <SidebarScrollWrapper>
         <SidebarItem
           icon={MoneyIcon}
           to="cost-insights"
           text="Cost Insights"
         />
        </SidebarScrollWrapper>
        <SidebarItem icon={CreateComponentIcon} to="create" text="Create..." />
        {/* End global nav */}
        <SidebarDivider />
        <SidebarScrollWrapper>
          <RBACSidebarItem />
          <SidebarItem icon={TextsmsOutlined} to="feedback" text="Feedback" />
          <SidebarItem icon={PlaylistPlayIcon} to="playlist" text="Playlists" />
          <SidebarItem icon={BuildIcon} to="toolbox" text="ToolBox" />
          <SidebarItem icon={Announcement} to="announcements" text="Announcements" />
          <SidebarItem icon={DesktopWindowsIcon} to="chatgpt" text="ChatGPT" />
          <RequirePermission
            permission={timeSaverPermission}
            errorPage={<></>}
          >
            <SidebarItem
              icon={Timelapse}
              to="time-saver"
              text="TimeSaver"
            />
          </RequirePermission>
          {/* <SidebarItem icon={DoneAllIcon} to='soundcheck' text='Soundcheck' /> */}
        </SidebarScrollWrapper>
        <SidebarDivider />
        <SidebarGroup label="Management" icon={ <BuildIcon />}>
          <RequirePermission
            permission = { devtoolsAccessPermission }
            errorPage={<></>}
          >
            <SidebarItem icon={BuildIcon} to="devtools" text="DevTools" />
            <SidebarItem icon={EmojiObjectsIcon} to="tech-insights" text="Tech insight" />
          </RequirePermission>
          <RequirePermission
            permission={ opsgenieAccessPermission }
            errorPage={<></>}
          >
            <SidebarItem icon={ReportProblemIcon} to="opsgenie" text="Incidents" />
          </RequirePermission>
          <RequirePermission
            permission={ mspAccessPermission }
            errorPage={<></>}
          >
            {/* <InsightsSidebarItem /> */}
            <SidebarItem icon={NextWeekIcon} to="msp-dashboard" text="MSP 1 Click Deployment" />
          </RequirePermission>
        </SidebarGroup>
        <Shortcuts />
      </SidebarGroup>
      <SidebarSpace />
      <SidebarDivider />
      <SidebarGroup
        label="Settings"
        icon={<UserSettingsSignInAvatar />}
        to="/settings"
      >
        <SidebarSettings />
      </SidebarGroup>
    </Sidebar>
    {children}
  </SidebarPage>
);
