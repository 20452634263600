// packages/app/src/scaffolder/ValidateKebabCase/ValidateKebabCaseExtension.tsx
import React from 'react';
import { FieldProps, FieldValidation } from '@rjsf/utils';
import FormControl from '@mui/material/FormControl';
import InputLabel from '@mui/material/InputLabel';
import Input from '@mui/material/Input';
import FormHelperText from '@mui/material/FormHelperText';
/*
 This is the actual component that will get rendered in the form
*/
export const BitbucketSecrets = ({
  onChange,
  rawErrors,
  // required,
  formData,
}: FieldProps<string>) => {
  return (
  <>
    <FormControl
      variant="standard"
      margin="normal"
      // required={required}
      error={rawErrors!.length > 0 && !formData}
      >
      <InputLabel htmlFor="key">Secret Key</InputLabel>
      <Input
        id="key"
        aria-describedby="entityName"
        onChange={e => onChange(e.target?.value)}
      />
      <FormHelperText id="entityName">
        can only contain alphanumeric characters
      </FormHelperText>
    </FormControl>
  </>
  );
};

/*
 This is a validation function that will run when the form is submitted.
  You will get the value from the `onChange` handler before as the value here to make sure that the types are aligned\
*/

export const validateBitbucketSecrets = (
  value: string,
  validation: FieldValidation,
) => {
  const kebabCase = /^[a-z0-9-_]+$/g.test(value);

  if (kebabCase === false) {
    validation.addError(
      `Only use letters, numbers, hyphen ("-") and underscore ("_").`,
    );
  }
};
