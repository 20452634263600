import React from 'react'
import Icon from "@mui/material/Icon";
import Terraform from './assets/terraform.svg'

const TerraformIcon = () => (
  <Icon>
      <img src={Terraform} alt='Terraform Icom' height="100%" width="100%"/>
  </Icon>
)

export default TerraformIcon;